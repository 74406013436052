import React, { useState } from 'react';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { HomeOutlined, FileAddOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import "./App.css"
import Create_medical_record from "./components/create-medical-record"

const { Header, Content, Footer } = Layout;

// Define los items del menú con iconos
const items = [
  {
    key: '1',
    label: "Inicio",
    icon: <HomeOutlined />,
  },
  {
    key: '2',
    label: "Creación de Ficha Médica",
    icon: <FileAddOutlined />,
  },
  {
    key: '3',
    label: "Pacientes",
    icon: <UserOutlined />,
  },
  {
    key: '4',
    label: "Calendario",
    icon: <CalendarOutlined />,
  },
];

// Define los componentes para cada opción
const Inicio = () => <div>Componente Inicio</div>;
const SeguimientoPaciente = () => <div>Componente Seguimiento de Paciente</div>;
const Calendario = () => <div>Componente Calendario</div>;

function App() {
  const [selectedKey, setSelectedKey] = useState('1'); // Default selected key
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Renderiza el componente basado en la opción seleccionada
  const renderContent = () => {
    switch (selectedKey) {
      case '1':
        return <Inicio />;
      case '2':
        return <Create_medical_record/>;
      case '3':
        return <SeguimientoPaciente />;
      case '4':
        return <Calendario />;
      default:
        return <div>Componente no encontrado</div>;
    }
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          items={items}
          onClick={(e) => setSelectedKey(e.key)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <img className="logoEmpresarial" src="/logo-no-background.png" width={"120px"} />
      </Header>
      <Content
        style={{
          padding: "0 48px",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
            height: "100%"
          }}
          className="contentDiv"
        >
          {renderContent()}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Contactar al desarrollador
      </Footer>
    </Layout>
  );
}

export default App;
